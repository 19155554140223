<template>
  <div>
    <v-card>
      <v-toolbar class="elevation-0" dark color="primary" height="40">
        <h2>Visitas</h2>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col>
        <v-row>
          <v-container>
            <!--centered-->
            <v-tabs   height="35" @change=changeTab($event)   v-model="currentItem" >
              <v-tab  v-for="item in items" :key="item" :href="'#tab-' + item"  @change="clickInTab(item)"> 
                  {{ item }}                 
              </v-tab>
            </v-tabs>
            <v-tabs-items 
              v-model="currentItem" 
              style="padding: 10 10px 10px 0px;">
              <v-tab-item v-for="item in items" :key="item" :value="'tab-' + item">
            
                <v-card v-if='item=="Datos"' class="pb-5 pt-5">                       
                  <v-row justify="center" class="mt-0 mb-6">
                    <v-col sm="2" lg="2" cols="12" class="pb-0 pt-1"> 
                      <s-date  label="Fecha" v-model="VstDate"></s-date>
                    </v-col>
                     
                    <v-col sm="2" lg="2" cols="12" class="pt-1 pb-0" >
                      <s-text                  
                        label="Serie"
                        v-model="visitSchedule.VstSerie"/>
                    </v-col>
                    <v-col  sm="2" lg="2" cols="12" class="pt-1 pb-0">
                      <s-text                  
                        label="Numero"
                        v-model="visitSchedule.VstNumber"/>
                    </v-col>
                    <v-col sm="4" lg="4" cols="12" class="s-col-form">           
                      <s-select-supervice
                        clearable
                        label="Supervisor"
                        v-model="visitSchedule.VstSupervisorPrsID"
                        full /> 
                    </v-col>  
                    <v-col sm="2" lg="2" cols="12" class="pt-1 pb-0" >
                      <s-text                  
                        label="ID Programación"
                        number
                        :value="visitSchedule.TvID"/>
                    </v-col>   
                    <v-col sm="2" lg="2" cols="12" class="pb-0 pt-1" >
                      <s-search-lot-by-cod-senasa
                        @searchSenasa="searchSenasa($event)"/>
                    </v-col>
                  
                    <v-col sm="2" lg="2" cols="12" class="pt-1 pb-0" >
                      <s-text
                        ref="refCodSenasa"
                        readonly
                        label="ID Lote"
                        v-model="visitSchedule.FltID" />
                    </v-col>
                    <v-col sm="4" lg="2" cols="12" class="pt-1 pb-0" >
                      <s-text
                        ref="refCodSenasa"
                        readonly
                        label="Lote"
                        v-model="visitSchedule.FltName"/>
                    </v-col>
                    <v-col sm="4" lg="3" cols="12" class="pt-1 pb-0" >
                      <s-text
                        ref="refCodSenasa"
                        readonly
                        label="Nombre de Fundo"
                        v-model="visitSchedule.FagName"/>
                    </v-col>
                    <v-col sm="4" lg="3" cols="12" class="pt-1 pb-0">
                      <s-text
                        ref="refCodSenasa"
                        readonly
                        label="Productor"
                        v-model="visitSchedule.PrdCardName"/>
                    </v-col>
                    <v-col sm="4" lg="4" cols="12" class="pt-1 pb-0" >
                      <s-text
                        ref="refCodSenasa"
                        readonly
                        label="Direccion Fundo"
                        v-model="visitSchedule.FagAddress"/>
                    </v-col>
                  
                    <v-col sm="4" lg="2" cols="12" class="pt-1 pb-0">
                      <s-text
                        ref="refCodSenasa"
                        readonly
                        label="Zona"
                        v-model="visitSchedule.ZonIDText"/>
                    </v-col>
                    <v-col sm="4" lg="2" cols="12" class="pt-1 pb-0" >
                      <s-text
                        ref="refCodSenasa"
                        readonly
                        label="Sub Zona"
                        v-model="visitSchedule.SzoIDText"/>
                    </v-col>
                  
                    <v-col sm="4" lg="2" cols="12" class="pt-1 pb-0" >
                      <s-text
                        ref="refCodSenasa"
                        readonly
                        label="Variedad"
                        v-model="visitSchedule.VrtName"/>
                    </v-col>
                    <v-col sm="4" lg="2" cols="12" class="pt-1 pb-0">
                      <s-text
                        ref="refCodSenasa"
                        readonly
                        label="Tipo de Cultivo"
                        v-model="visitSchedule.TypeCropName"/>
                    </v-col>
                  </v-row>
                </v-card>
                <div v-if='item=="Aplicaciones"' class="pb-5 pt-5">                
        <!--SzoIDText ZonIDText-->
                    <!-- <visit-Performed-application
                      :visitSchedule="visitSchedule"
                      :isEditabled ="this.isEditabled"
                      :dataVisitPerformedApplications ="this.TasVisitPerformedApplications"
                      v-model="TasVisitPerformedApplications"/>
                    <v-row>
                      <v-col>
                        <v-data-table
                          :items="TasVisitPerformedApplications"
                          :headers="[
                            { text: '', value: 'Line' },
                            { text: 'Aplicaciones Fitosanitarias y Fertilizantes Realizadas', value: 'TasProductsApplicationName'},
                            { text: 'Fecha Inicio', value: 'PapDate' },
                            { text: 'Fecha Fin', value: 'PapDateEnd' },
                            { text: 'Tipo aplicación', value: 'TypeApplicationName' },
                            { text: 'Gasto combustible', value: 'PapFuelExpense', width: '50' },
                            { text: 'Acciones', value: 'Actions', align: 'center', width: '90',sortable: false},
                          ]"
                          dense
                          :items-per-page="5">
                          <template v-slot:item.Actions="{ item }">
                            <v-btn
                              @click="clickDeletePerformedAplications(item)"
                              x-small
                              color="error"
                              elevation="0"
                              block>
                              <v-icon style="font-size: 16px !important"
                                >fas fa-times</v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.PapDate="{ item }">
                            {{ moment(item.PapDate).format('DD-MM-YYYY') }}
                          </template>
                          <template v-slot:item.PapDateEnd="{ item }">
                            {{ moment(item.PapDateEnd).format('DD-MM-YYYY') }}
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row> -->
                </div>

                <v-card v-if='item=="Fenologia"' class="pb-5 pt-5">  
                  <v-row>
                    <v-col   cols="12" class="pr-0">
                      <h3 class="mb-2">Etapa Fenológica (%)</h3>
                      <v-col cols="2" >
                        <s-select-definition
                          :def="1207"
                          add
                        ></s-select-definition>
                      </v-col>
                     
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="2" cols="2"   v-for="i in itemsTypePhenological">
                        <s-text :label="i.DedDescription ? i.DedDescription : i.TypeStagePheologicalName + ' (%)'" v-model="i.StpPercentage" decimal />
                      </v-col>
                  </v-row>
                </v-card>
                <v-card v-if='item=="Actividades Realizadas / Evaluación Fitosanitaria"' class="pb-5 pt-5">                       
                  <v-row>         
                    <!--EVALUACION FOTOSANITARIA-->
                    <v-col lg="6" cols="12">
                      <v-row>
                        <v-col>
                          <h3>Evaluación Fitosanitaria</h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          @click="addPhenological()"
                          x-small
                          class="mr-3 mt-1"
                          elevation="5"
                          fab>
                          <v-icon height="26" style="font-size: 14px !important"
                            >fas fa-plus</v-icon>
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-col lg="5" class="pt-0 pb-0" cols="6">
                          <s-select-plagues
                            :typeCultive="visitSchedule.TypeCultive"
                            return-object
                            clearable
                            ref="objPlague"
                            v-model="objPlague"
                            label="Enfermadad/Plaga/Maleza"/>
                        </v-col>
                        <v-col lg="4" class="pt-0 pb-0" cols="6">
                          <s-select-definition
                            :def="1208"
                            add
                            clearable
                            return-object
                            v-model="objOrganPlant"
                            label="Organo"/>
                        </v-col>
                        <v-col lg="3" class="pt-0 right" cols="12">
                          <s-text
                            placeholder="000"
                            label="Pocentaje"
                             
                            v-mask="'###'"
                            v-model="PercentagePlague"/>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0">
                          <v-data-table
                            :headers="[
                              { text: '', value: 'Line', sortable: false },
                              { text: 'Plaga', value: 'PlName', sortable: false },
                              { text: 'Organo', value: 'TypeOrganName', sortable: false},
                              { text: '%', value: 'PhePercentage', width: 50, sortable: false,align: 'center'},
                              { text: 'Acciones', value: 'Actions', align: 'center', width: '90',sortable: false},
                            ]"
                            dense
                            :items="itemsPhenological"
                            :items-per-page="5">
                            <template v-slot:item.Actions="{ item }">
                              <v-btn
                                @click="clickDeletePhenological(item)"
                                x-small
                                color="error"
                                elevation="0"
                                block>
                                <v-icon style="font-size: 16px !important"
                                  >fas fa-times</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!--Actividades Realizadas-->
                    <v-col lg="6" cols="12">
                      <v-row>
                        <v-col>
                          <h3>Actividades Realizadas</h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          x-small
                          class="mr-3 mt-1"
                          elevation="5"
                          fab
                          @click="openModelNewActivities()">
                          <v-icon height="26" style="font-size: 16px !important"
                            >fas fa-plus</v-icon>
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-col lg="4" class="pt-0 pb-0" cols="12">
                          <s-select-definition
                            :def="1204"
                            v-model="objTypeActivity"
                            add
                            @input="inputDataActivities($event)"
                            label="Actividad"
                            return-object/>
                        </v-col>
                        <v-col lg="4" class="pt-0 pb-0" cols="12">
                          <s-date
                            label="Fecha Inicio"
                            v-model="ActBeginDate"
                          ></s-date>
                        </v-col>
                        <v-col lg="4" class="pt-0 pb-0" cols="12">
                          <s-date label="Fecha Fin" v-model="ActEndDate"></s-date>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-data-table
                            :items="itemsActivities"
                            :headers="[
                              { text: '', value: 'Line' },
                              { text: 'Detalles', value: 'viewDetails'},
                              { text: 'Actividad', value: 'TypeActivityName' },
                              { text: 'Fecha Inicio', value: 'PacBeginDate' },
                              { text: 'Fecha Fin', value: 'PacEndDate' },
                              { text: 'Acciones', value: 'Actions', align: 'center', width: '90',sortable: false},
                            ]"
                            dense
                            :items-per-page="5">
                            <template v-slot:item.Actions="{ item }">
                              <v-btn
                                @click="clickDeleteActivities(item)"
                                x-small
                                color="error"
                                elevation="0"
                                block>
                                <v-icon style="font-size: 16px !important"
                                  >fas fa-times</v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:item.viewDetails="{ item }">
                              <v-btn class="ma-2"
                                    x-small
                                    @click="viewDetailActivities(item)"
                                    outlined
                                    color="indigo">
                                <v-icon small>fas fa-eye theme--light</v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:item.PacBeginDate="{ item }">
                            {{ moment(item.PacBeginDate).format('DD-MM-YYYY') }}
                            </template>
                            <template v-slot:item.PacEndDate="{ item }">
                              {{ moment(item.PacEndDate).format('DD-MM-YYYY') }} 
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card v-if='item=="Recomendaciónes"' class="pb-5 pt-5">
                  <v-row>
                    <v-col class="pb-0">
                      <h3>Recomendaciones</h3>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      x-small
                      class="mr-3 mt-1"
                      elevation="5"
                      @click="addRecomendation()"
                      fab>
                      <v-icon height="26" style="font-size: 16px !important"
                        >fas fa-plus</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row><v-divider></v-divider></v-row>
                  <v-row justify="center">
                    <v-col lg="2" class="pb-0" cols="12">
                      <s-select-definition
                        :def="1214"
                        v-model="objTypeApplication"
                        label="Tipo de Aplicación"
                        return-object/>
                    </v-col>
                    <v-col :lg="numberInstallations2==2 ? 2 : numberInstallations4==4 ? 2 : 3" class="pb-0" cols="12">
                      <s-select-plagues
                        return-object
                        clearable
                        :typeCultive="visitSchedule.TypeCultive"
                        ref="objPlagueRecomendation"
                        v-model="objPlagueRecomendation"
                        label="Enfermedad/Plaga/Maleza"/>
                    </v-col>
                    <v-col :lg="numberInstallations2==2 ? 2 : numberInstallations4==4 ? 2 : 3" class="pb-0" cols="12">
                      <s-select-listsupplies
                        :full="objPlagueRecomendation == null ? false : true"
                        label="Producto"
                        return-object
                        :PlID="
                          objPlagueRecomendation == null ? 0 : objPlagueRecomendation.PlID"
                        v-model="objProductRecomedation"
                        @input="inputProductRecomendation($event)"/>
                    </v-col>
                    <v-col  lg="2" class="pb-0" cols="12">
                      <s-text
                        decimal
                        label="Dosis"
                        ref="DoceRecomendation"
                        v-model="DoceRecomendation"/>
                    </v-col>
                    <v-col v-if="numberInstallations4==4" lg="2" class="pb-0" cols="12">
                      <s-text
                        number
                        v-model="recomentationObj.RcmGatheringZoneNumber"
                        ref="refRcmGatheringZoneNumber"
                        label="N° Zona acopio">
                      </s-text>
                    </v-col>
                    <v-col v-if="numberInstallations5==5" lg="2" class="pb-0" cols="12">
                      <s-switch
                        v-model="recomentationObj.RcmOperative"
                        label="Operativa">
                      </s-switch>
                    </v-col>
                    <v-col v-if="numberInstallations4==4" lg="2" class="pb-0" cols="12">
                      <s-switch
                        v-model="recomentationObj.RcmWithEasels"
                        label="con caballetes">
                      </s-switch>
                    </v-col>
                    <v-col v-if="numberInstallations3==3" lg="2" class="pb-0" cols="12">
                      <s-switch
                        v-model="recomentationObj.RcmClosedAndLocked"
                        label="Cerrado y con llave">
                      </s-switch>
                    </v-col>
                    <v-col v-if="numberInstallations2==2" lg="2" class="pb-0" cols="12">
                      <s-switch
                        v-model="recomentationObj.RcmWithChairs"
                        label="Sillas y mesas">
                      </s-switch>
                    </v-col>
                    <v-col v-if="numberInstallations2==2" lg="2" class="pb-0" cols="12">
                      <s-switch
                        v-model="recomentationObj.RcmWithRoof"
                        label="Techos">
                      </s-switch>
                    </v-col>
                    <v-col v-if="numberInstallations1==1" lg="2" class="pb-0" cols="12">
                      <s-switch
                        v-model="recomentationObj.Rcmhandwashing"
                        label="Lavado de manos">
                      </s-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table
                        :items="itemsRecomendation"
                        :headers="[
                          { text: '', value: 'Line', width: '70' },
                          { text: 'Detalles', value: 'viewDetails' , width:'70'},
                          { text: 'Tipo Aplicación', value: 'TypeApplicationName',width: '120'},
                          { text: 'Enfermedad/Plaga/Maleza', value: 'PlName',width: '200'},
                          { text: 'Producto', value: 'LsName' },
                          { text: 'Dosis', value: 'RcmDose', width: '120' },
                          { text: 'Acciones', value: 'Actions',align: 'center',width: '90',sortable: false},
                        ]"
                        dense
                        :items-per-page="5">
                        <template v-slot:item.Actions="{ item }">
                          <v-btn
                            @click="clickDeleteRecomendations(item)"
                            x-small
                            color="error"
                            elevation="0"
                            block>
                            <v-icon style="font-size: 16px !important"
                              >fas fa-times</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.viewDetails="{ item }">
                          <v-btn class="ma-2"
                                  x-small
                                  @click="viewDetailRecomendations(item)"
                                  outlined
                                  color="indigo">
                            <v-icon small >fas fa-eye theme--light</v-icon>
                          </v-btn>
                        </template>

                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0"><h3>Notas</h3></v-col>
                  </v-row>
                  <v-row><v-divider></v-divider></v-row>
                  <v-row>
                    <v-col>
                      <s-textarea v-model="VstNoteRecomendation" />
                    </v-col>
                  </v-row>
                </v-card>
                <v-card v-if='item=="Actividades culturales"' class="pb-5 pt-5">                    
                  <v-row>
                    <v-col class="pb-3">
                      <h3>Actividades Culturales</h3>
                    </v-col>
                  </v-row>
                  <v-row><v-divider></v-divider></v-row>
                  <v-row>
                    <v-col lg="2" class="pb-0 pt-0" v-for="i in itemsTypeActivities">
                      <s-switch
                        :label="
                          i.DedDescription ? i.DedDescription : i.TypeActivityName"
                        v-model="i.AcuStatus" color="primary"
                        
                      ></s-switch>
                    </v-col>
                  </v-row>                
                </v-card>
            
              </v-tab-item>
            </v-tabs-items>




          
            <!-- <v-row>
              <v-col class="pb-0">
                <h3>Evaluación de Instalaciones</h3>
              </v-col>
            </v-row>
            <v-row><v-divider></v-divider></v-row> -->
            <v-row>
              <!-- <v-col v-for="i in itemsTypeInstallations" lg="2" class="pb-0 pt-0">
                <s-switch
                  :label="i.DedDescription ? i.DedDescription : i.TypeInstallationName"
                  v-model="i.IneStatus" color="primary"
                  @input="inputTypeArea($event, i)"
                ></s-switch>
              </v-col>
              <v-col lg="2" cols="12" class="pb-0 pt-0">
                <s-switch
                  inset label="LIBRE RESIDUOS" v-model="VstNoWaste" color="success"
                ></s-switch>
              </v-col> -->
            </v-row>
           
            <v-row>
              <v-col class="pb-0">
                <h3>Observaciones</h3>
              </v-col>
            </v-row>
            <v-row><v-divider></v-divider></v-row>
            <v-row>
              <v-col>
                <s-textarea v-model="VstObservation" />
              </v-col>
            </v-row>



            <v-row justify="center">
              <v-spacer />
              <v-col sm="3" cols="12">
                <v-btn block color="warning" rounded @click="$emit('close')"
                  >Cancelar</v-btn>
              </v-col>
              <v-col sm="3" cols="12">
                <v-btn color="primary" rounded @click="save()" block
                  >Guardar</v-btn>
              </v-col>
            </v-row>
          </v-container>

        </v-row>
      </v-col>
    </v-card>

    <!-- dialog de detalles de actividades ralizadas  -->
    <v-dialog
      persistent
      width="800"
      v-if="DetailActivities"
      v-model="DetailActivities">
      <v-card>
        <s-toolbar 
          close
          @close="DetailActivities = false" 
          label="Detalles de la Actividad" 
          color="primary" 
          dark>
        </s-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12">
            <v-data-table
              height="100"
              :items="itemsDetailsActivities"
              :headers="headerItemsDetail"
              hide-default-footer
              :items-per-page="-1"
              dense>
            </v-data-table></v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- dialog de detalles de recomendaciones  -->
    <v-dialog
      persistent
      width="600"
      v-if="DetailRecomendations"
      v-model="DetailRecomendations">
      <v-card>
        <s-toolbar
         close
         @close="DetailRecomendations=false"
         label="Detalles de la recomendación"
         color="primary"
         dark>
        </s-toolbar>
        <v-container>
          <v-row>
            <v-data-table
              :items="itemsDetailRecomendations"
              :headers="[
                {text : 'Lavado de manos', value :'RcmhandwashingName'},
                {text : 'Sillas y mesas', value : 'RcmWithChairsName'},
                {text : 'Num zona acopio', value : 'RcmGatheringZoneNumber'},
                {text : 'Cerrado y con llave', value : 'RcmClosedAndLockedName'},
                {text : 'Con caballetes', value : 'RcmWithEaselsName'},
                {text : 'Operativa', value : 'RcmOperativeName'},
                {text : 'Techos', value : 'RcmWithRoofName'},
              ]">

            </v-data-table>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

   <v-dialog
    persistent
    v-model="modalAddActivities"
    v-if="modalAddActivities"
    width="800">
    <v-card >
      <s-toolbar
      color="primary"
      dark
      label="Registro de actividades">

      </s-toolbar>
      <v-divider></v-divider>
      <v-container>
        <div v-if="numberActivities==1">
          <v-row>
            <v-col  cols="4">
              <s-select-definition
                label="Sistema"
                @input="inputTypeSistema($event)"
                return-object
                v-model="objActiviy.TypeIrrigation"
                :def="1352">
              </s-select-definition>
            </v-col>
            <v-col v-if="isOcult" cols="4">
              <s-select-definition
                label="Tipo sistema"
                return-object
                v-model="objActiviy.TypeDetailIrrigation"
                :def="1353">
              </s-select-definition>
            </v-col>
            <v-col v-if="isOcult" cols="4">
              <s-text v-model="objActiviy.PacFuelCost" label="Gasto de combustible" decimal ref="refPacFuelCost"></s-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <s-text v-model="objActiviy.PacFlowIrrigation" label="Caudal de riego (L/s)" decimal ref="refPacFlowIrrigation"></s-text>
            </v-col>
            <v-col cols="6">
              <s-text v-model="objActiviy.PacTimeIrrigation" label="Tiempo de riego (H)" decimal ref="refPacTimeIrrigation"></s-text>
            </v-col>
          </v-row>
        </div>
        <div v-if="numberActivities==2">
          <v-row justify="center">
            <v-col cols="6">
              <s-text v-model="objActiviy.PacNumberOfTrees" number label="Número de arboles podados" ref="refPacNumberOfTrees" ></s-text>
            </v-col>
          </v-row>
        </div>
        <div v-if="numberActivities==3">
          <v-row>
            <v-col cols="6">
              <s-select-definition
                label="Tipo de deshierbo"
                return-object
                v-model="objActiviy.TypeWeeding"
                @input="inputTypeDeshi($event)"
                :def="1354">
              </s-select-definition>
            </v-col>
            <v-col v-if="isOcult">
              <s-text v-model="objActiviy.PacAmountFuelGallons" label="Cantidad de combustible (Gal)" decimal ref="refPacAmountFuelGallons"></s-text>
            </v-col>
          </v-row>
        </div>
        <div v-if="numberActivities==4">
          <v-row>
            <v-col cols="6">
              <s-select-definition
                label="Tipo de trampa"
                return-object
                v-model="objActiviy.TypeTrap"
                :def="1355">
              </s-select-definition>
            </v-col>
            <v-col>
              <s-text v-model="objActiviy.PacNumberTrap" label="Número de trampas" number ref="refPacNumberTrap"></s-text>
            </v-col>
          </v-row>
        </div>
        <v-card-actions style="padding-top: 40px">
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
           dark
            @click="modalAddActivities = false">
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="addActivities()">
            GUARDAR
          </v-btn>
        </v-card-actions>
      </v-container>

    </v-card>
   </v-dialog>
  </div>
</template>
<script>
import SSearchLotByCodSenasa from "@/components/HarvestSchedule/sSearchLotByCodSenasa.vue";
import _sDefinition from "@/services/General/DefinitionService.js";
import _sVisit from "@/services/Technicalassistance/Visit/Visit.js";
import SSelectPlagues from "@/components/TecnicalAssistence/Plagues/SSelectPlagues";
import sSelectListsupplies from "@/components/TecnicalAssistence/ListSupplies/SSelectListsupplies";
import visitPerformedApplication from "@/views/TechnicalAssistance/Visits/VisitPerformedApplication";
import SSelectDefinition from '../../../components/Utils/SSelectDefinition.vue';

import SSelectSupervice from "@/components/HarvestSchedule/SSelectSupervice";

export default {
  components: {
    SSelectPlagues,
    sSelectListsupplies,
    visitPerformedApplication,
    SSearchLotByCodSenasa,
    SSelectDefinition,
    SSelectSupervice,
  },
  props: {
    visitSchedule: { type: Object, default: null },
    isEditabled : {type: Boolean, default: false},
  },
  data() {
    return {
      currentItem: 'tab-Funciones',   
      items: ['Datos', 'Aplicaciones', 'Fenologia', 'Actividades Realizadas / Evaluación Fitosanitaria', 'Recomendaciónes', 'Actividades culturales'],
      PrdCardName : "",
      DetailRecomendations : false,
      itemsDetailRecomendations : [],
      headerItemsDetail: [],
      itemsDetailsActivities : [],
      DetailActivities : false,
      itemsCountInstallations : 0,
      NumberLote:"",
      recomentationObj : {},
      numberInstallations : 0,
      numberInstallations1 : 0,
      numberInstallations2 : 0,
      numberInstallations3 : 0,
      numberInstallations4 : 0,
      numberInstallations5 : 0,
      objActiviy : {},
      numberActivities : 0,
      isOcult : false,
      modalAddActivities : false,
      lotSenasa: null,
      NtpID: null,
      TasVisitPerformedApplications: [],
      TasDeleteVisitPerformedApplications : [],
      DoceApplication: null,
      UnitMeasurement: null,
      objProductApplication: null,
      objProductRecomedation: null,
      itemsRecomendation: [],
      itemsDeleteRecomendation : [],
      objPlagueRecomendation: null,
      RecDoce: "",
      itemsActivities: [],
      ActBeginDate: null,
      ActEndDate: null,
      objTypeActivity: null,
      DoceRecomendation: "",
      objProductRecomendation: null,
      objTypeApplication: null,
      VstDate: "",
      VstNoWaste: null,
      VstNoteRecomendation: "",
      VstObservation: "",
      itemsTypePhenological: [],
      objOrganPlant: null,
      PercentagePlague: "",
      objPlague: null,
      itemsPhenological: [],
      itemsTypeInstallations: [],
      itemsTypeActivities: [],

      itemsDeletePhenological : [],
      itemsDeleteTypeInstallations : [],
      itemsDeleteActivities : [],
      validateSistem2 : false,
      validateDeshi : false,
      FagName: "",
      TypeSupplierName: "",
      VrtDescription: "",
      TypeCropText: "",
      FagAddress: ""
    };
  },
  created() {

  //  console.log('datos que me traen de la vista general', this.visitSchedule )
  //  console.log('verificando si es editado o no ', this.isEditabled)
  
    if (this.isEditabled) {
      //CARGAR VISITA
      _sVisit
        .find(this.visitSchedule.VstID, this.$fun.getUserID())
        .then((r) => {
          this.itemsTypePhenological = r.data.TasVisitStagePhenologicals;
          this.itemsPhenological = r.data.TasVisitPhytosanitaryEvaluations;
          this.itemsActivities = r.data.TasVisitPerformedActivities;
          this.itemsTypeInstallations = r.data.TasVisitInstallationEvaluations;
          this.itemsRecomendation = r.data.TasVisitRecomendations;
          this.TasVisitPerformedApplications = r.data.TasVisitPerformedApplications;
          this.itemsTypeActivities = r.data.TasVisitActivityCulturals;
          this.VstDate = r.data.VstDate;

          console.log('datos de la recomendación', this.itemsRecomendation);

          this.VstNoteRecomendation = r.data.VstNoteRecomendation;
          this.VstObservation = r.data.VstObservation;
          this.VstNoWaste = r.data.VstWasteFree;

          this.itemsCountInstallations = this.itemsTypeInstallations.length;

          // this.recomentationObj.Rcmhandwashing = this.itemsRecomendation[0].Rcmhandwashing;
          // this.recomentationObj.RcmClosedAndLocked = this.itemsRecomendation[0].RcmClosedAndLocked;
          // this.recomentationObj.RcmGatheringZoneNumber = this.itemsRecomendation[0].RcmGatheringZoneNumber;
          // this.recomentationObj.RcmOperative = this.itemsRecomendation[0].RcmOperative;
          // this.recomentationObj.RcmWithChairs = this.itemsRecomendation[0].RcmWithChairs;
          // this.recomentationObj.RcmWithEasels = this.itemsRecomendation[0].RcmWithEasels;
          // this.recomentationObj.RcmWithRoof = this.itemsRecomendation[0].RcmWithRoof;

          this.recomentationObj = {};
         
         

        });
    } else {
      //TIPO DE ETAPA FENOLOGICA; para completar los stext
      _sDefinition.definitiongroup(1207, 0, this.$fun.getUserID()).then((r) => {
        this.itemsTypePhenological = r.data;
      });
      //TIPO DE EVALUACIÓN DE INSTALACIONES
      _sDefinition.definitiongroup(1209, 0, this.$fun.getUserID()).then((r) => {
        this.itemsTypeInstallations = r.data;
        // this.itemsCountInstallations = this.itemsTypeInstallations.length;
      });


      // TasVisitPerformedApplication 
      _sDefinition.definitiongroup(1204, 0, this.$fun.getUserID()).then((r) => {
        this.itemsTypeActivities = r.data;
        // this.itemsCountInstallations = this.itemsTypeInstallations.length;
      });


      console.log('actividades culturales',this.itemsTypeActivities )
    }


  },
  methods: {
    clickInTab(n){

    },
    changeTab(n) {
      // this.tabSelected = n;
            
    },
    viewDetailRecomendations(item){
      
      item.RcmClosedAndLockedName = (item.RcmClosedAndLocked == null) ? 'NO' : 'SI';
      item.RcmOperativeName = (item.RcmOperative == null) ? 'NO' : 'SI';
      item.RcmWithChairsName = (item.RcmWithChairs == null) ? 'NO' : 'SI';
      item.RcmWithEaselsName = (item.RcmWithEasels == null) ? 'NO' : 'SI';
      item.RcmWithRoofName = (item.RcmWithRoof == null) ? 'NO' : 'SI';
      item.RcmhandwashingName = (item.Rcmhandwashing == null) ? 'NO' : 'SI';

      // this.itemsDetailRecomendations.push(RcmClosedAndLockedName);

      this.itemsDetailRecomendations = [];
      console.log('datos de las recomendaciones', item);
      this.DetailRecomendations = true;
      this.itemsDetailRecomendations.push(item);

      


    },

    viewDetailActivities(item){

      if(item.TypeActivity < 5){

        this.itemsDetailsActivities = [];
        this.DetailActivities = true; 
        this.itemsDetailsActivities.push(item);
        this.headerItemsDetail = [];
  
        if(this.itemsDetailsActivities[0].TypeActivity == 1){
          this.headerItemsDetail.push(
            { text: 'Tipo de actividad', value: 'TypeActivityName'},
            { text: 'Sistema de riego', value: 'TypeIrrigationName'},
            { text: 'tipo de Sistema', value: 'TypeDetailIrrigationName'},
            { text: 'Gasto de combustible', value: 'PacFuelCost'},
            { text: 'Caudal de riego', value: 'PacFlowIrrigation'},
            { text: 'Tiempo de riego', value: 'PacTimeIrrigation'},
          );
        }
  
        if(this.itemsDetailsActivities[0].TypeActivity == 2){
          this.headerItemsDetail.push(
            {  text: 'Tipo de actividad', value: 'TypeActivityName'},
            { text: 'Arboles podados', value: 'PacNumberOfTrees'},
          );
        }
  
        if(this.itemsDetailsActivities[0].TypeActivity == 3){
          this.headerItemsDetail.push(
            {  text: 'Tipo de actividad', value: 'TypeActivityName'},
            { text: 'Tipo de deshierbo', value: 'TypeWeedingName'},
            { text: 'Cantidad de combustible', value: 'PacAmountFuelGallons'},
          );
        }
  
        if(this.itemsDetailsActivities[0].TypeActivity == 4){
          this.headerItemsDetail.push(
            {  text: 'Tipo de actividad', value: 'TypeActivityName'},
            { text: 'Tipo de trampa', value: 'TypeTrapName'},
            { text: 'Nùmero de trampas', value: 'PacNumberTrap'},
          );
        }

      } else {
        this.$fun.alert("No hay detalles para mostrar", "warning")
      }


      console.log('estamos en la vista de detalles de actividades', this.itemsDetailsActivities);
    },

    inputTypeArea(element, value){  
      var itemValor = value.DedValue ? value.DedValue : value.TypeInstallation;
      
      if(element == 1){
        switch (itemValor){

          case 1: this.numberInstallations1 = 1; break;
          case 2: this.numberInstallations2 = 2; break;
          case 3: this.numberInstallations3 = 3; break;
          case 4: this.numberInstallations4 = 4; break;
          case 5: this.numberInstallations5 = 5; break;
          default : this.numberInstallations = 0; break;
        }
        
      } else {

        switch (itemValor){
          case 1: this.numberInstallations1 = 0; break;
          case 2: this.numberInstallations2 = 0; break;
          case 3: this.numberInstallations3 = 0; break;
          case 4: this.numberInstallations4 = 0; break;
          case 5: this.numberInstallations5 = 0; break;
          default : this.numberInstallations = 0; break;
        }
      }
    },

    inputTypeDeshi(element){
      if(element != undefined){
        if(element.DedValue == 3){
          this.validateDeshi = true;
          this.isOcult = true; 
        } else {
          this.validateDeshi = false;
          this.isOcult = false;
        }
      }
    },

    inputTypeSistema(element){

      if(element != undefined){
        if(element.DedValue == 2){
          this.validateSistem2 = true; 
          this.isOcult = true; 
        } else {
          this.validateSistem2 = false; 
          this.isOcult = false;
        }
      }

    },
    inputProductRecomendation(product) {
      if (product) this.unitRecomendation = product;
    },
    searchSenasa(lot) {
      this.lotSenasa = lot;
      this.PrdCardName = lot.PrdCardName;
      console.log("search senasa", lot);
      this.visitSchedule = lot;

      this.visitSchedule.TypeCropName = lot.TypeCropText;
      this.visitSchedule.VrtName = lot.VrtDescription;

    },
    orderRecomendations() {
      let i = 0;
      this.itemsRecomendation.map((e) => {
        i++;
        e.Line = i;
      });
    },
    clickDeletePerformedAplications (item){

      if(this.TasVisitPerformedApplications.length > 0) {
        if(item.VstID > 0) {
          this.TasDeleteVisitPerformedApplications.push(
            this.TasVisitPerformedApplications.filter(x=> x.Line == item.Line)[0],
            );

          this.TasDeleteVisitPerformedApplications.forEach(element => {
            element.SecStatus = 0;
          });
        }

        this.TasVisitPerformedApplications = this.TasVisitPerformedApplications.filter(
          x => x.Line != item.Line
        );

        this.OrderPerformedAplications();
      }

    },

    OrderPerformedAplications() {
      let i = 0;
      this.TasVisitPerformedApplications.map((e) => {
        i++;
        e.Line = i;
      });
    },

    clickDeleteRecomendations(item) {

      if(this.itemsRecomendation.length > 0){
        if(item.VstID > 0){
          this.itemsDeleteRecomendation.push(
            this.itemsRecomendation.filter(x=> x.Line == item.Line)[0],
          );

          this.itemsDeleteRecomendation.forEach(element => {
            element.SecStatus = 0;
          });
        }

        this.itemsRecomendation = this.itemsRecomendation.filter(
          (x) => x.Line != item.Line
        );
        this.orderRecomendations();

      }

     
    },

    addRecomendation() {

      if (this.DoceRecomendation == "" || this.DoceRecomendation == "") {
        this.$refs.DoceRecomendation.error("Ingrese Dosis Recomendada", "warning");
        return;
      }

      if(this.numberInstallations4 == 4 ) {

        if(this.recomentationObj.RcmGatheringZoneNumber <= 0 ) {
          this.$refs.refRcmGatheringZoneNumber.error("Ingrese zona recomendada");
          return;
        }
      }


      this.itemsRecomendation.push({

        VstID : this.visitSchedule.VstID ? this.visitSchedule.VstID : 0,

        TypeApplication: this.objTypeApplication
          ? this.objTypeApplication.DedValue
          : null,
        TypeApplicationName: this.objTypeApplication
          ? this.objTypeApplication.DedDescription
          : null,
        Line: this.itemsRecomendation.length + 1,
        PlID: this.objPlagueRecomendation
          ? this.objPlagueRecomendation.PlID
          : null,
        PlName: this.objPlagueRecomendation
          ? this.objPlagueRecomendation.PlDescription
          : null,
        LsID: this.objProductRecomedation
          ? this.objProductRecomedation.LsID
          : null,
        LsName: this.objProductRecomedation
          ? this.objProductRecomedation.LsDescription
          : null,
        TypeUnit: this.objProductRecomedation
          ? this.objProductRecomedation.LsUnitID
          : null,
        TypeUnitName: this.unitRecomendation
          ? this.unitRecomendation.DedDescription
          : null,
        Rcmhandwashing: this.recomentationObj.Rcmhandwashing
          ? this.recomentationObj.Rcmhandwashing
          : null,
        RcmWithChairs: this.recomentationObj.RcmWithChairs
          ? this.recomentationObj.RcmWithChairs
          : null,
        RcmWithRoof: this.recomentationObj.RcmWithRoof
          ? this.recomentationObj.RcmWithRoof
          : null,
        RcmClosedAndLocked: this.recomentationObj.RcmClosedAndLocked
          ? this.recomentationObj.RcmClosedAndLocked
          : null,
        RcmWithEasels: this.recomentationObj.RcmWithEasels
          ? this.recomentationObj.RcmWithEasels
          : null,
        RcmGatheringZoneNumber : this.recomentationObj.RcmGatheringZoneNumber
          ? this.recomentationObj.RcmGatheringZoneNumber
          : null,
        RcmOperative : this.recomentationObj.RcmOperative
          ? this.recomentationObj.RcmOperative
          : null,
        RcmDose: this.DoceRecomendation,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      });

      console.log('data recomendation a gurdar',this.itemsRecomendation )
    },
    save() {

      console.log("this.visitSchedule.VstNumber", this.visitSchedule.VstNumber)
      console.log("this.visitSchedule.VstSerie", this.visitSchedule.VstSerie)
           
      
       console.log(" fltID", this.visitSchedule.FltID)

      if (this.visitSchedule.FltID == undefined || this.visitSchedule.FltID == "") {
        this.$fun.alert("El ID Lote es obligatorio", "warning");
        return;
      }
      
      if (this.visitSchedule.VstSerie == undefined || this.visitSchedule.VstSerie == "") {
        this.$fun.alert("La serie es Obligatorio", "warning");
        return;
      }
      if (this.visitSchedule.VstNumber == undefined || this.visitSchedule.VstNumber == "") {
        this.$fun.alert("El numero de documento es Obligatorio", "warning");
        return;
      }
 

      this.$fun.alert("Seguro de guardar?", "question").then((r) => {
        if (r.value) {

        
          // se debe evaluar esta parte
          if (
            // this.visitSchedule.VstID == 0 ||
            // this.visitSchedule.VstID == null
            !this.isEditabled
          ) {
            this.itemsTypePhenological.map((item) => {
              item.TypeStatePhenological = item.DedValue;
              item.SecStatus = 1;
              item.UsrCreateID = this.$fun.getUserID();
            });
            this.itemsTypeInstallations.map((item) => {
              item.TypeInstallation = item.DedValue;
              item.SecStatus = 1;
              item.UsrCreateID = this.$fun.getUserID();
            });
            this.itemsTypeActivities.map((item) => {
              item.TypeActivity = item.DedValue;
              item.SecStatus = 1;
              item.UsrCreateID = this.$fun.getUserID();
            });
          }
          //samir
          // if (this.TasVisitPerformedApplications.length <= 0) {
          //   this.$fun.alert(
          //     "Falta Registrar Aplicaciones Realizadas",
          //     "warning"
          //   );
          //   return;
          // }
        
          let ObjVisit = {
            FltID: this.visitSchedule.FltID,
            SzoID:  this.visitSchedule.SzoID, 
            ZonID:  this.visitSchedule.ZonID,
            VstSupervisorPrsID: this.visitSchedule.VstSupervisorPrsID,
            // NtpID: this.visitSchedule.NtpID,             
            UsrCreateID: this.$fun.getUserID(),
            UsrUpdateID: this.$fun.getUserID(),
            SecStatus: 1,
            VstStatus: 1,
            TasVisitActivityCulturals: this.itemsTypeActivities,
            TasVisitInstallationEvaluations: this.itemsTypeInstallations,
            // TasVisitPerformedActivities: this.itemsActivities,
            TasVisitPerformedApplications: null,
            // TasVisitPhytosanitaryEvaluations: this.itemsPhenological,
            // TasVisitRecomendations: this.itemsRecomendation,
            TasVisitStagePhenologicals: this.itemsTypePhenological,
            // TasVisitPerformedApplications: this.TasVisitPerformedApplications,
          };


          ObjVisit.TasVisitPerformedActivities = [...this.itemsActivities, ...this.itemsDeleteActivities];
          ObjVisit.TasVisitPhytosanitaryEvaluations = [...this.itemsPhenological, ...this.itemsDeletePhenological];
          ObjVisit.TasVisitRecomendations = [...this.itemsRecomendation, ...this.itemsDeleteRecomendation];
          ObjVisit.TasVisitPerformedApplications = [...this.TasVisitPerformedApplications, ...this.TasDeleteVisitPerformedApplications];

          console.log('aplicaciones realizadas', this.TasVisitPerformedApplications)

          ObjVisit.TvID = this.visitSchedule.TvID;
          ObjVisit.VstDate = this.VstDate;
          ObjVisit.VstID = this.isEditabled ? this.visitSchedule.VstID : 0;
          ObjVisit.VstWasteFree = this.VstNoWaste;
          ObjVisit.VstNoteRecomendation = this.VstNoteRecomendation;
          ObjVisit.VstObservation = this.VstObservation;
          console.log("ObjVisit vid", ObjVisit);
          
          _sVisit.save(ObjVisit, this.$fun.getUserID()).then((r) => {
            this.$fun.alert("Guardado con Exito", "success");
            this.$emit("close");
          });
        }
      });
    },
    orderActivities() {
      let i = 0;
      this.itemsActivities.map((e) => {
        i++;
        e.Line = i;
      });
    },
    clickDeleteActivities(item) {

      console.log('datos para elimimar', item);
      console.log('cantidad', this.itemsActivities.length);


      if(this.itemsActivities.length > 0){
        if(item.VstID > 0){
          this.itemsDeleteActivities.push(
            this.itemsActivities.filter(x=> x.Line == item.Line)[0],
          );

          this.itemsDeleteActivities.forEach(element => {
            element.SecStatus = 0;
          });
        }

        this.itemsActivities = this.itemsActivities.filter(
          x => x.Line != item.Line
        );
        this.orderActivities();

      }

      console.log('datos eliminados', this.itemsDeleteActivities)

    },

    openModelNewActivities (){

      if(this.numberActivities != 0){
        this.modalAddActivities = true;
      } else {
        this.addActivities();
      }


    },

    addActivities() {
      
      if (!this.objTypeActivity) {
        this.$fun.alert("Seleccione Actividad", "warning");
        return;
      }

      if(this.modalAddActivities) {

        switch (this.numberActivities) {
          case 1 : 

            if (this.validateSistem2) {
              if(this.objActiviy.PacFuelCost <= 0){
                 this.$refs.refPacFuelCost.error('Debe Ingresar el gasto del combustible',"error");
                return;
              }

              if(this.objActiviy.PacFlowIrrigation <= 0){
                this.$refs.refPacFlowIrrigation.error('Debe ingresar el caudal del riego', "error");
                return;
              }
              if(this.objActiviy.PacTimeIrrigation <= 0) {
                this.$refs.refPacTimeIrrigation.error('Debe Ingresar el tiempo de riego', "error");
                return;
              }

            } else {

              
              if(this.objActiviy.PacFlowIrrigation <= 0){
                this.$refs.refPacFlowIrrigation.error('Debe ingresar el caudal del riego', "error");
                return;
              }
              if(this.objActiviy.PacTimeIrrigation <= 0) {
                this.$refs.refPacTimeIrrigation.error('Debe Ingresar el tiempo de riego', "error");
                return;
              }

            }
            
           
          break;
          case 2 : 
            if(this.objActiviy.PacNumberOfTrees <= 0){
              this.$refs.refPacNumberOfTrees.error('Debe Ingresar número de arboles podados', "error");
              return;
            }
          break;
          case 3:
            if(this.validateDeshi){
              if(this.objActiviy.PacAmountFuelGallons <= 0){
                this.$refs.refPacAmountFuelGallons.error('Debe ingresar la cantidad de galones', "error");
                return;
              }
            }
          break;
          case 4:
            if(this.objActiviy.PacNumberTrap <= 0){
              this.$refs.refPacNumberTrap.error('Debe ingresar la cantidad trampas', "error");
              return;
            }
          break;
        }
      }


      this.itemsActivities.push({

        VstID : this.visitSchedule.VstID ? this.visitSchedule.VstID : 0, 

        TypeIrrigation: this.objActiviy.TypeIrrigation ? this.objActiviy.TypeIrrigation.DedValue : null,
        TypeDetailIrrigation: this.objActiviy.TypeDetailIrrigation ? this.objActiviy.TypeDetailIrrigation.DedValue : null,
        PacFlowIrrigation: this.objActiviy.PacFlowIrrigation ? this.objActiviy.PacFlowIrrigation : null ,
        PacTimeIrrigation: this.objActiviy.PacTimeIrrigation ? this.objActiviy.PacTimeIrrigation : null,
        PacFuelCost: this.objActiviy.PacFuelCost ? this.objActiviy.PacFuelCost : null,
        PacNumberOfTrees: this.objActiviy.PacNumberOfTrees ? this.objActiviy.PacNumberOfTrees : null,
        TypeWeeding: this.objActiviy.TypeWeeding ? this.objActiviy.TypeWeeding.DedValue : null,
        PacAmountFuelGallons : this.objActiviy.PacAmountFuelGallons ? this.objActiviy.PacAmountFuelGallons : null,
        TypeTrap : this.objActiviy.TypeTrap ? this.objActiviy.TypeTrap.DedValue : null,
        PacNumberTrap : this.objActiviy.PacNumberTrap ? this.objActiviy.PacNumberTrap : null,

        TypeIrrigationName : this.objActiviy.TypeIrrigation ? this.objActiviy.TypeIrrigation.DedDescription : null,
        TypeDetailIrrigationName : this.objActiviy.TypeDetailIrrigation ? this.objActiviy.TypeDetailIrrigation.DedDescription : null,
        TypeTrapName :  this.objActiviy.TypeTrap ? this.objActiviy.TypeTrap.DedDescription : null,
        TypeWeedingName : this.objActiviy.TypeWeeding ? this.objActiviy.TypeWeeding.DedDescription : null,


        TypeActivity: this.objTypeActivity ? this.objTypeActivity.DedValue : null,
        Line: this.itemsActivities.length + 1,
        PacBeginDate: this.ActBeginDate,
        PacEndDate: this.ActEndDate,
        TypeActivityName: this.objTypeActivity ? this.objTypeActivity.DedDescription : null,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      });

      // this.itemsActivities = [];

      this.objActiviy = {};
      this.modalAddActivities=false;

      // console.log('actividades a guardar', this.itemsActivities)
    },

    orderPhenological() {
      let i = 0;
      this.itemsPhenological.map((e) => {
        i++;
        e.Line = i;
      });
    },
    clickDeletePhenological(item) {
      if(this.itemsPhenological.length > 0){
        if(item.VstID > 0) {
          this.itemsDeletePhenological.push(
            this.itemsPhenological.filter(x => x.Line == item.Line)[0]
          );

          this.itemsDeletePhenological.forEach(element => {
            element.SecStatus = 0;
          });
        }

        this.itemsPhenological = this.itemsPhenological.filter(
          x => x.Line != item.Line
        );
        this.orderPhenological();
      }

      console.log('datos eliminados phenological',this.itemsDeletePhenological )
      
    },

    addPhenological() {

      if (!this.objPlague) {
        this.$fun.alert("Complete plaga, maleza o enfermedad", "warning");
        // this.$refs.objPlague.focus();
        return;
      }
      if (!(this.PercentagePlague > 0 && this.PercentagePlague <= 100)) {
        // this.$refs.PercentagePlague.error("Porcentaje Incorrecto");
        this.$fun.alert("Porcentaje Incorrecto", "warning")
        return;
      }
       
      // this.$refs.PercentagePlague.setColor("primary");
      this.itemsPhenological.push({

        VstID : this.visitSchedule.VstID ? this.visitSchedule.VstID : 0, 
        TypeOrganPlant: this.objOrganPlant ? this.objOrganPlant.DedValue : null,
        Line: this.itemsPhenological.length + 1,
        PlID: this.objPlague.PlID,
        PlName: this.objPlague.PlDescription,
        TypeOrganName: this.objOrganPlant
          ? this.objOrganPlant.DedDescription
          : null,
        PhePercentage: this.PercentagePlague,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      });
      this.PercentagePlague = null;
      this.objOrganPlant = null;
      console.log("this.itemsPhenological -> add", this.itemsPhenological);
    },

    // // este metodo iba en en mismo componente del metodo de abajo
    updateDataActivities(items) {
      this.itemsTypeActivities = items;
      console.log('datos de la actividad para actualizar');
    },


    inputDataActivities(items){

      if(items != undefined){
       switch (items.DedValue) {
         case 1: this.numberActivities = 1; break;
         case 2: this.numberActivities = 2; break;
         case 3: this.numberActivities = 3; break;
         case 4: this.numberActivities = 4; break;
         default : this.numberActivities = 0
                   this.modalAddActivities=false         
         break;
       }
      }
    }
  },
  watch: {
    objProductApplication() {
      this.UnitMeasurement = this.objProductApplication.LsUnitID;
    },
  },
  mounted(){
     console.log("this.visitSchedule", this.visitSchedule)
     
    //  this.lotSenasa.FagName = this.visitSchedule.FagName;
    //  this.lotSenasa.FltName = this.visitSchedule.FltName;
    //  this.lotSenasa.PrdCardName = this.visitSchedule.PrdCardName;
    //  this.lotSenasa.TypeCropName = this.visitSchedule.TypeCropName;
    //  this.lotSenasa.TypeSupplierName = this.visitSchedule.TypeSupplierName;
    //  this.lotSenasa.VrtName = this.visitSchedule.VrtName;
    //  this.lotSenasa.FagAddress = this.visitSchedule.FagAddress; 


     console.log("this.lotsenasa", this.lotSenasa)

  }
};
</script>
